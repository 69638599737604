// Generated by Framer (8289486)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {WMhifDpTc: {hover: true}};

const cycleOrder = ["WMhifDpTc"];

const variantClassNames = {WMhifDpTc: "framer-v-v88wlt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 19, delay: 0, duration: 0.05, ease: [0, 0, 1, 1], mass: 8.6, stiffness: 425, type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate = (_, t) => `translateY(-50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "WMhifDpTc", image: Xkr4NOSt4 = {src: new URL("assets/sJqwm2SR21PrtLb2cT2yEEYxMBY.svg", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WMhifDpTc", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AgceY", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 196, intrinsicWidth: 196, pixelHeight: 196, pixelWidth: 196, src: new URL("assets/sJqwm2SR21PrtLb2cT2yEEYxMBY.svg", import.meta.url).href}} className={cx("framer-v88wlt", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WMhifDpTc"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"WMhifDpTc-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", sizes: "1px", ...toResponsiveImage(Xkr4NOSt4)}} className={"framer-1k07nvq"} layoutDependency={layoutDependency} layoutId={"LufUXpBuf"} transition={transition} {...addPropertyOverrides({"WMhifDpTc-hover": {background: {alt: "", fit: "fit", sizes: "115px", ...toResponsiveImage(Xkr4NOSt4)}, transformTemplate}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AgceY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AgceY .framer-3som4o { display: block; }", ".framer-AgceY .framer-v88wlt { height: 61px; position: relative; width: 81px; }", ".framer-AgceY .framer-1k07nvq { aspect-ratio: 1.3278688524590163 / 1; flex: none; height: 100%; left: 0px; overflow: visible; position: absolute; top: 0px; width: var(--framer-aspect-ratio-supported, 81px); }", ".framer-AgceY .framer-v-v88wlt .framer-v88wlt { cursor: pointer; }", ".framer-AgceY.framer-v-v88wlt.hover .framer-v88wlt { aspect-ratio: 1.3278688524590163 / 1; height: var(--framer-aspect-ratio-supported, 61px); }", ".framer-AgceY.framer-v-v88wlt.hover .framer-1k07nvq { height: var(--framer-aspect-ratio-supported, 87px); left: -17px; right: -17px; top: 49%; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 61
 * @framerIntrinsicWidth 81
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fuCp55ddQ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Xkr4NOSt4":"image"}
 */
const Frameri9NSWLL_N: React.ComponentType<Props> = withCSS(Component, css, "framer-AgceY") as typeof Component;
export default Frameri9NSWLL_N;

Frameri9NSWLL_N.displayName = "BAM";

Frameri9NSWLL_N.defaultProps = {height: 61, width: 81};

addPropertyControls(Frameri9NSWLL_N, {Xkr4NOSt4: {__defaultAssetReference: "data:framer/asset-reference,sJqwm2SR21PrtLb2cT2yEEYxMBY.svg?originalFilename=Explosion.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Frameri9NSWLL_N, [])